import "../styles/tails-config.css";
import "../styles/styles.css";
import PageWithLayoutType from "@/lib/pageWithLayouts";
import { SessionProvider, useSession } from "next-auth/react";
import type { AppProps } from "next/app";
import { ReactElement, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "@/shared/utils";
import Lottie from "lottie-react";
import loadingAnime from "../public/loading.json";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

type AppLayoutProps = AppProps & {
  Component: PageWithLayoutType;
  pageProps: any;
};

export default function App({ Component, pageProps }: AppLayoutProps) {
  utils.setCookie("timezone", utils.obterTimezone());
  utils.setCookie(
    "horario",
    new Date().getHours().toString() + ":" + new Date().getMinutes().toString()
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js");
      });
    }
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={String(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY)}
      useRecaptchaNet={true}
      useEnterprise={true}
    >
      {/* <Auth> */}
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
        <ToastContainer />
      </SessionProvider>
      {/* </Auth> */}
    </GoogleReCaptchaProvider>
  );
}

function Auth({ auth, children }: any) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true });
  if (status === "loading") {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Lottie
          className="w-52 h-52 xl:col-span-2 m-auto"
          animationData={loadingAnime}
          autoPlay={true}
          loop={true}
        />
      </div>
    );
  }

  return children;
}
