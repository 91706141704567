import React, { HtmlHTMLAttributes } from "react";
import Cookies from 'js-cookie'
import { LoteModel } from "@/models/LoteModel";
import { CartModelProps } from "@/pages/checkout/cart";
import moment from 'moment-timezone';
import { User } from "next-auth";
import rolesDto from "@/models/rolesDto";
import * as _ from 'lodash'

class Utils {
    static _mesasCart = 'mesasArr';
    static _totalCart = 'totalCarrinho';
    static _qtdCart = 'qtdCart'

    get mesasCart() {
        return Utils._mesasCart;
    }

    get totalCart() {
        return Utils._totalCart;
    }

    get qtdCart() {
        return Utils._qtdCart;
    }

    /**
     * e.target.files[0]
     * @param file 
     * @returns Promise
     */
    imageFromInput(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (file) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    resolve(e?.target?.result);
                };

                reader.readAsDataURL(file);
            } else {
                resolve("")
            }
        })
    }

    dataBrToInput(data: string) {        
        if (data === undefined || data === "") return ""
        
        var dataArr = data.split("-");
        if (dataArr.length !== 3) return "";
    
        // Troque a ordem dos elementos do array para criar a data no formato correto (YYYY-MM-DD)
        var date = new Date(`${dataArr[2]}-${dataArr[1]}-${dataArr[0]}`);
        
        if (isNaN(date.getTime())) {
            // Se a data for inválida, retorne uma string vazia
            return "";
        }
    
        return date.toISOString().substring(0, 10);
    }

    currencyRegex(value: any, sibolo?: boolean) {
        if (!value) return '0'

        value = value.toString().replace(/\D/g, "")
        value = value.replace(/(\d)(\d{2})$/, "$1,$2")
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ".")
        const cifrao = sibolo ? 'R$ ' : ''

        if (value.length == 2)
            value = `0,${value}`

        if (value.length == 1)
            value = `0,0${value}`

        return cifrao + " " + value
    }

    currencyMask(e: React.ChangeEvent<HTMLInputElement>) {
        let value = e.target.value
        value = this.currencyRegex(value)
        e.target.value = value
        return e
    }

    localStorageSet(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    localStorageGet(key: string) {
        localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) ?? '') : '';

    }

    getNomeclatura(nomeclatura: string, view: boolean) {
        if (nomeclatura == undefined || nomeclatura == '') return ''

        if (nomeclatura == 'mesa')
            return view ? 'Mesa' : 'mesa'

        if (nomeclatura == 'assento')
            return view ? 'Assento' : 'assento'

        if (nomeclatura == 'individual')
            return view ? 'Ingresso Individual' : 'individual'
        if (nomeclatura == 'passaporte')
            return view ? 'Passaporte' : 'passaporte'
    }

    pad(pad: string, str: string, padLeft: boolean) {
        if (typeof str === 'undefined')
            return pad;
        if (padLeft) {
            return (pad + str).slice(-pad.length);
        } else {
            return (str + pad).substring(0, pad.length);
        }
    }

    padLeft(str: string, length: number, padCharacter: string) {
        padCharacter = padCharacter || ' '; // Default padding character is space
        return String(str).padStart(length, padCharacter);
    }

    formatDate(date: Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour "0" should be "12"
        const minutess = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutess + " " + ampm;
        return date.getDate() + "/" + new Intl.DateTimeFormat('pt', { month: '2-digit' }).format(date) + "/" + date.getFullYear() + " " + strTime;
    }

    horaDaImpressao() {
        const hora = new Date()
        return this.formatDate(hora)
    }

    converteBase64(data: string) {
        return Buffer.from(data).toString('base64')
    }

    getCookie(chave: string) {
        return Cookies.get(chave) ?? ''
    }

    setCookie(chave: string, valor: string) {
        Cookies.set(chave, valor)
    }

    removeCookie(chave: string) {
        Cookies.remove(chave)
    }

    getTimezone() {
        return Cookies.get('timezone') ?? ''
    }

    getMesasCookie(): any[] {
        const mesasString = this.getCookie(Utils._mesasCart)
        return mesasString == '' ? [] : JSON.parse(mesasString)
    }

    addMesasCokkie(arrMesasString: string) {
        const arr = arrMesasString.split(';').filter((o) => o != '');
        var mesas = this.getMesasCookie()

        if (mesas.length == 0) {
            this.setCookie(Utils._mesasCart, JSON.stringify(arr))
        } else {
            arr.forEach((item: any) => {
                const tem = mesas.filter((o: string) => o == item)

                if (tem.length == 0) {
                    mesas.push(item)
                } else {
                    mesas = mesas.filter((o: string) => o != item)
                }
            })
            
            this.setCookie(Utils._mesasCart, JSON.stringify(mesas))
        }
    }

    removeMesaCookie(id: string) {
        const mesasString = this.getCookie(Utils._mesasCart)
        const mesas = mesasString == '' ? null : JSON.parse(mesasString)
        var novoArr = []

        mesas.forEach((item: string) => {
            if (item != id.toString()) {
                novoArr.push(item)
            }
        })

        this.setCookie(Utils._mesasCart, JSON.stringify(mesas))
        //console.log(this.getCookie('mesasCart'))
    }

    getCartCookie() {
        const cookie = Cookies.get('cart') ?? ''
        return cookie
    }

    deleleCartCookie() {
        Cookies.remove('cart')
    }

    addCart(ingressos: LoteModel[]) {
        const cookiesList = Cookies.get('cart')
        let list: LoteModel[] = JSON.parse(cookiesList ?? '[]')

        ingressos.forEach((ingresso: any) => {
            if (ingresso.ingresso_id == null) {
                let tem = false

                list.forEach((itemCart: any) => {
                    if (ingresso.id == itemCart.id) {
                        itemCart.quantidade = ingresso.quantidade
                        tem = true
                    }
                })

                if (!tem) {
                    list.push(ingresso)
                }
            }
        })

        Cookies.set('cart', JSON.stringify(list))
    }

    setCookieCart(cookie_id: string, force = false) {
        let tem = Cookies.get('cart')

        if (tem == undefined || force) {
            Cookies.set('cart', cookie_id.toString())
            tem = cookie_id
        }
        
        return tem
    }

    deleleQtdCart() {
        Cookies.remove('qtdCart')
    }

    setQtdCartCookie(carts: CartModelProps[], salvar = true) {
        
        let soma = 0
        let valor = 0
        var mesas: string[] = []

        carts?.forEach((item: CartModelProps) => {
            soma = soma + item.quantidade
            valor = valor + (item.subtotal ?? 0)

            if (item.area.nomeclatura == 'mesa') {
                item.ingressos.forEach((ingresso: any) => {
                    if (ingresso.ingresso_id == null) {
                        mesas.push(ingresso.id.toString())
                    }
                })
            }
        })

        if (salvar) {
            Cookies.set(Utils._qtdCart, soma.toString())            
            Cookies.set(Utils._totalCart, valor.toString())
            Cookies.set(Utils._mesasCart, JSON.stringify(mesas))
        }

        return {
            qtdCart: soma.toString(),
            totalCart: valor.toString(),
            mesasCart: mesas
        }
    }

    getQtdCartCookie() {
        const r = Cookies.get('qtdCart')
        return r != undefined ? r : '0'
    }

    randomNumber() {
        const timestamp = Math.floor(Date.now() / 1000)
        const random = Math.floor(Math.random() * 10000) + 1
        return random + '' + timestamp
    }

    valorTotalCart(cart: CartModelProps[]) {
        let soma = 0

        cart.forEach((item: any) => {
            soma = soma + item.subtotal
        })

        return soma
    }

    valorRealParaInt(valor: string) {
        let novo = _.replace(valor, '.', '')
        novo = _.replace(novo, ',', '')
        novo = _.replace(novo, '%', '')
        novo = novo.trim()
        return Number(novo)
    }

    removeObjeto(objetos: any, chave: any, valor: any) {
        return objetos.filter(function (val: any) {
            return val[chave] !== valor;
        });

    }

    keyElement() {
        return Math.random().toString()
    }

    keyGenerate(multiplica: number) {
        const r = (Math.floor(Math.random() * 1000000000) + 1) * multiplica  //Bettween 1 and 10
        return r.toString()
    }

    pagseguroConverteStatus(status: string) {
        switch (status) {
            case 'PAID':
                return 'PAGO'
                break;
            case 'AUTHORIZED':
                return 'AUTORIZADO'
                break;
            case 'WAITING':
                return 'AGUARDANDO'
                break;
            default:
        }
    }

    validarCPF(cpf: any) {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove todos os caracteres que não são números

        if (cpf.length !== 11) { // Verifica se o CPF tem 11 caracteres
            return false;
        }

        // Verifica se todos os números são iguais (ex.: 111.111.111-11)
        for (var i = 0; i < 10; i++) {
            if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
                break;
            }
        }
        if (i === 10) {
            return false;
        }

        // Calcula os dois dígitos verificadores
        var soma = 0;
        var resto;
        for (var i = 1; i <= 9; i++) {
            soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }
        if (resto !== parseInt(cpf.charAt(9))) {
            return false;
        }

        soma = 0;
        for (var i = 1; i <= 10; i++) {
            soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }
        if (resto !== parseInt(cpf.charAt(10))) {
            return false;
        }

        // CPF válido
        return true;
    }

    formatarCPF (cpf: any) {
        if (!cpf && cpf.length < 11) return cpf

        return cpf.slice(0, 3) + "." + cpf.slice(3, 6) + "." + cpf.slice(6, 9) + "-" + cpf.slice(9);
    }

    formatarCNPJ (cnpj: any) {
        if (!cnpj && cnpj.length < 11) return cnpj

        return cnpj.slice(0, 2) + "." + cnpj.slice(2, 5) + "." + cnpj.slice(5, 8) + "/" + cnpj.slice(8, 12) + "-" + cnpj.slice(12);
    }

    verificaMaiuscula(str: string) {
        for (var i = 0; i < str.length; i++) {
            if (str[i] === str[i].toUpperCase() && str[i] !== str[i].toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    primeiraLetraMaiuscula(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    primeiroNome(nomeCompleto?: string) {
        if (nomeCompleto) {
            return nomeCompleto.split(' ')[0]
        }

        return ''
    }

    obterTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    obterFusoHorario() {
        const timezone = this.obterTimezone()
        const agora = moment();
        return moment.tz(agora, timezone).format('Z');
    }

    fusoHorarioConvert(hora?: string) {
        hora = hora ? hora : ''
        if (process.env.NEXT_PUBLIC_NODE_ENV == 'developer') {
            return hora
        } else {
            let fuso = this.obterFusoHorario()
            fuso = fuso.split(":")[0]
            const horario = moment('03-15-2023 ' + hora)
            return horario.add(parseInt(fuso), 'hours').format('HH:mm')
        }


    }

    calculaConveniencia(total: number, taxa: number) {
        let conveniencia = (total * (taxa / 100)) / 100
        return Number(conveniencia.toFixed(2).replaceAll('.', '').replaceAll(',', ''))
    }

    temEspacoEmBranco(str: string) {
        // verifica se a string contém espaços em branco
        if (/\s/.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    removeEspacoEmBranco(str: string) {
        return str.trim()
    }

    ehAdmin(roles?: string[]) {
        return roles && roles?.length > 0 ? roles.includes('admin') : false
    }

    ehInfluencer(roles?: string[]) {
        return roles && roles?.length > 0 ? roles.includes('influencer') : false
    }

    ehPromotor(roles?: string[]) {
        return roles && roles?.length > 0 ? roles.includes('promotor') : false
    }

    roleTroduzir(role: string) {
        if (role == rolesDto.ADMIN) {
            return 'Administrador'
        }
        if (role == rolesDto.INFLUENCER) {
            return 'Influencer'
        }
        if (role == rolesDto.USER) {
            return 'usuário'
        }
        if (role == rolesDto.PROMOTOR) {
            return 'Promotor'
        }
    }

    dateToBr(date: string) {
        return moment(date).format('DD-MM-YYYY');
    }

    dateTimeToBr (date: string) {
        return moment(date).format('DD-MM-YYYY HH:mm:ss');
    }

    dateTimeToMMSS (date: string) {
        return moment(date).format('HH:mm:ss');
    }


    urlAmigavel(text?: string) {
        if (!text) return ''

        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove acentos
            .replace(/([^\w]+|\s+)/g, '-') // substitui espaço e outros caracteres por hífen
            .replace(/\-\-+/g, '-') // substitui múltiplos hífens por um único hífen
            .replace(/(ˆ-+|-+$)/, '') // remove hífens extras do final ou do início da string
            .toLowerCase(); // deixa o texto em minúsculo
    }

    somenteNumeros(texto?: string) {
        return texto ? texto.replace(/\D/g, "") : texto;
    }

    chunk(array: any[], quantidade: number) {
        const grupos = [];
        let grupoAtual = [];

        for (let i = 0; i < array.length; i++) {
            grupoAtual.push(array[i]);

            if (grupoAtual.length === quantidade || i === array.length - 1) {
                grupos.push(grupoAtual);
                grupoAtual = [];
            }
        }

        return grupos;
    }

    groupByFromKey(array: any[], key: string) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }

    async forEachChaveObjeto(obj: any, callback: any) {
        for (let key of Object.keys(obj)) {
            await callback(key, obj[key]);
        }
    }

    /**
   * Para forEach assincrono -
   * await asyncForEach(receipts, async (eachItem) => {
   *  await ...
   * })
   * @param array
   * @response callback(item, i)
   */
    async asyncForEach<T>(
        array: Array<T>,
        callback: (item: any, index: any) => void,
    ) {
        if (!array) {
            callback(null, null);
        } else {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index);
            }
        }
    }
}

export default new Utils()